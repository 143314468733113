<template>
  <div style="height:100%">
    <iframe class="iframe-box" src="https://camera.dxwalk.com:9010/yili/project/authorization" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
export default {
  name: 'HangingManagement'
}
</script>

<style>
.iframe-box {
  border: none !important;
}
</style>